<template>
	<ValidationForm
		#default="{ handleSubmit, invalid, dirty }"
		ref="validationForm"
	>
		<ViewLayout>
			<template #header-title>
				Configure Cookie Policy Table
			</template>
			<template #header-caption>
				Configure a cookie policy table
			</template>
			<template #header-after>
				<Stepper
					:step.sync="currentStep"
					:steps="steps"
					:in-progress="!editMode"
				/>
			</template>
			<template #content>
				<div class="mb-2">
					<StepperItems
						:step="currentStep"
						:steps="steps"
					>
						<template #0>
							<configure-cookie-policy-table-details
								v-if="!isLoading"
								ref="detailsStep"
								:details="details"
								@next="nextStep"
								@edit-mode="isEditMode"
							/>
						</template>
						<template #1>
							<configure-cookie-policy-table-installation
								v-if="!isLoading"
								ref="installStep"
								@display-embed-code="showEmbedCode"
								@complete="completePolicyTable"
							/>
						</template>
					</StepperItems>
				</div>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="cancel">
							Back to Overview
						</SecondaryActionButton>
						<v-spacer />
						<template v-if="currentStep === 0">
							<PrimaryActionButton
								v-if="editMode && userCanCreateUpdate"
								class="mr-2"
								:disabled="!dirty"
								@click="handleSubmit($refs.detailsStep.updateDetails)"
							>
								Save
							</PrimaryActionButton>
							<SecondaryActionButton
								v-if="editMode"
								@click="nextStep"
							>
								Next
							</SecondaryActionButton>
							<PrimaryActionButton
								v-if="userCanCreateUpdate && !editMode"
								:disabled="invalid"
								@click="handleSubmit($refs.detailsStep.proceedFromDetails)"
							>
								Create
							</PrimaryActionButton>
						</template>
						<template v-else>
							<SecondaryActionButton @click="previousStep">
								Back
							</SecondaryActionButton>
							<PrimaryActionButton
								v-if="userCanCreateUpdate"
								class="ml-2"
								:disabled="!displayEmbedCode"
								@click="handleSubmit($refs.installStep.publishAndComplete)"
							>
								Complete
							</PrimaryActionButton>
						</template>
					</template>
				</PageActionRow>
			</template>
		</ViewLayout>
	</ValidationForm>
</template>

<script>
import { mapGetters } from 'vuex'
import ValidationForm from '../../../../../../../shared/components/validation-form.vue'
import ViewLayout from '../../../../../../../shared/layouts/view-layout.vue'
import PageActionRow from '../../../../../../../shared/components/page-action-row.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
import configureCookiePolicyTableDetails from './policy-tables-configure-details.vue'
import configureCookiePolicyTablesInstallation from './policy-tables-configure-installation.vue'
import Stepper from '../../../../../../../shared/components/stepper.vue'
import StepperItems from '../../../../../../../shared/components/stepper-items.vue'
import { CAN_CREATE_UPDATE_POLICY_TABLES } from '../../../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	page: {
		title: 'Configure Cookie Policy Table',
		meta: [
			{ name: 'description', content: 'The Create Cookie Policy Table page.' }
		]
	},
	components: {
		ViewLayout,
		Stepper,
		StepperItems,
		'configure-cookie-policy-table-details': configureCookiePolicyTableDetails,
		'configure-cookie-policy-table-installation': configureCookiePolicyTablesInstallation,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton,
		ValidationForm
	},
	data () {
		return {
			details: null,
			installation: null,
			isLoading: false,
			currentStep: 0,
			inProgress: true,
			steps: [
				{ slot: 0, title: 'Details', stepNumber: '01', stepComplete: false },
				{ slot: 1, title: 'Embed Code', stepNumber: '02', stepComplete: false }
			],
			editMode: false,
			displayEmbedCode: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_POLICY_TABLES)
		}
	},
	methods: {
		nextStep () {
			this.steps.forEach((step, index) => {
				if (index <= this.currentStep) {
					step.stepComplete = true
				}
			})
			if (this.currentStep < 1) {
				this.currentStep += 1
			}
			this.scrollToTop()
		},
		previousStep () {
			this.steps.forEach((step, index) => {
				if (index >= this.currentStep) {
					step.stepComplete = false
				}
			})
			this.currentStep -= 1
			this.scrollToTop()
		},
		completePolicyTable () {
			this.$router.push({ name: 'policy-tables' })
		},
		cancel () {
			this.$router.push({ name: 'policy-tables' })
		},
		scrollToTop () {
			window.scrollTo(0, 0)
		},
		isEditMode (editMode) {
			this.editMode = editMode
		},
		showEmbedCode (displayEmbedCode) {
			this.displayEmbedCode = displayEmbedCode
		}
	}
}
</script>
