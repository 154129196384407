<template>
	<!-- eslint-disable max-lines -->
	<div>
		<div class="cassie-vertical-md">
			<div
				v-if="formData.createdDate"
				class="text-caption"
			>
				Created By: {{ formData.createdByName }}
				{{ formData.createdDate }}
				<span
					v-if=" isEditMode && formData.modifiedByName !== ' ' && formData.modifiedDate !== ' '"
				>
					| Last Edited By: {{ formData.modifiedByName }} {{ formData.modifiedDate }}
				</span>
			</div>
			<SectionCard>
				<template #title>
					Details
				</template>
				<template #body>
					<div class="d-flex flex-row cassie-horizontal-sm">
						<TextField
							v-model="formData.policyTableName"
							label="Policy Table Name*"
							class="cassie-input-width-md"
							:disabled="!userCanCreateUpdate"
							rules="required"
						/>
						<Dropdown
							v-model="formData.brand"
							:label="brandLabel | useLabels"
							:disabled="!userCanCreateUpdate || isEditMode || !clientMultiBranded || singleBrand"
							class="cassie-input-width-md"
							:items="filteredBrandOptions"
							custom-sort
							:rules="{ required: clientMultiBranded}"
						/>
					</div>
					<div
						class="d-flex flex-column"
					>
						<FroalaEditor
							v-if="userCanCreateUpdate"
							v-model="formData.policyTableDescription"
							title="Description"
							class="cassie-input-width-xl"
							:rules="{required: false, max: 2000}"
						/>
						<v-textarea
							v-else
							v-dompurify-html="formData.policyTableDescription"
							light
							dense
							outlined
							readonly
							class="mt-2 cassie-disabled-textarea cassie-input-width-xl"
						/>
					</div>
				</template>
			</SectionCard>
			<SectionCard>
				<template #title>
					Cookie Scripts in Policy Table
				</template>
				<template #body>
					<div class="d-flex flex-row cassie-horizontal-sm">
						<Dropdown
							v-model="formData.selectedCategory"
							label="Cookie Category"
							class="cassie-input-width-md"
							:disabled="isEditMode && !userCanCreateUpdate"
							:items="filteredCategories"
							item-text="categoryName"
							item-value="categoryId"
						/>
					</div>
					<div class="d-flex flex-row cassie-horizontal-sm cassie-input-width-md">
						<Dropdown
							v-model="formData.selectedScript"
							label="Cookie Script"
							:disabled="
								!formData.selectedCategory ||
									formData.selectedCategory === 0 ||
									(isEditMode && !userCanCreateUpdate)
							"
							:items="
								availableCookieScripts.filter(
									(script) =>
										script.cookieCategoryId === formData.selectedCategory
								)
							"
							item-text="cookieScriptName"
							item-value="cookieScriptId"
							no-data-text="This Category has no Cookie Scripts available"
						/>
						<PrimaryActionButton
							:disabled="!formData.selectedScript"
							@click="addScriptToTable(formData.selectedScript)"
						>
							Add
						</PrimaryActionButton>
					</div>
					<div class="d-flex flex-column ">
						<v-alert
							v-if="formData.selectedCookieScripts.length === 0"
							light
						>
							<div
								class="grey--text lighten-4 text-center"
							>
								No Cookie Scripts have been added to this Policy Table
							</div>
						</v-alert>
						<DataTable
							v-else
							:headers="scriptHeadersComputed"
							:items="formData.selectedCookieScripts"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							:disable-sort="false"
							item-key="cookieScriptId"
							no-data-text="Loading Cookie Scripts...Please wait."
							hide-default-footer
						>
							<template #item.cookieDescription="{ item }">
								{{ trimText(item.cookieDescription, maxDisplayTextLength) }}
							</template>
							<template #item.action="{ item }">
								<IconButton
									tooltip-text="Edit Script"
									@click="openEditScriptDetailsModal(item)"
								>
									{{ !userCanCreateUpdate ? 'mdi-eye' : 'mdi-pencil' }}
								</IconButton>
								<IconButton
									v-if="
										item.displayOrder !==
											formData.selectedCookieScripts.length
									"
									:disabled="!userCanCreateUpdate"
									@click="moveScript(item, 'moveDown')"
								>
									mdi-arrow-down
								</IconButton>

								<IconButton
									v-if="item.displayOrder > 1"
									:disabled="!userCanCreateUpdate"
									@click="moveScript(item, 'moveUp')"
								>
									mdi-arrow-up
								</IconButton>
								<IconButton
									:disabled="!userCanCreateUpdate"
									tooltip-text="Remove Script"
									@click="removeScriptFromTable(item)"
								>
									mdi-close
								</IconButton>
							</template>
						</DataTable>
					</div>
				</template>
			</SectionCard>
			<SectionCard v-if="formData.selectedCookieScripts.length > 0">
				<template #title>
					Preview
				</template>
				<template #body>
					<cookie-policy-table-preview :cookies="cookiesForPreview" />
				</template>
			</SectionCard>
		</div>
		<!-- Loader Overlay -->
		<!-- Edit script form -->
		<script-details-form
			v-if="showScriptDetailsModal"
			:form="formData.scriptDetailsForm"
			@openEditScriptDetailsModal="openEditScriptDetailsModal"
			@cancelForm="closeEditScriptDetailsModal"
			@updateScriptDescription="updateScriptDescription"
		/>
	</div>
</template>

<script>
/* eslint-disable max-lines */
import { mapGetters, mapActions } from 'vuex'
import FroalaEditor from '../../../../shared/froala-editor.vue'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import ScriptDetailsForm from './policy-tables-configure-script-details-form.vue'
import CookiePolicyTablePreview from './policy-tables-configure-preview.vue'
import { CAN_CREATE_UPDATE_POLICY_TABLES } from '../../../../../../../shared/permissions/admin-portal-permissions.js'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import { singleBrand, defaultBrandInSelect, clientMultiBranded, brandOptions, UNIVERSAL_BRAND_ITEM, waitForLoad } from '../../../../../../../shared/state/brands.js'

const UNIVERSAL_BRAND = 0
export default {
	components: {
		'script-details-form': ScriptDetailsForm,
		FroalaEditor,
		SectionCard,
		'cookie-policy-table-preview': CookiePolicyTablePreview,
		PrimaryActionButton,
		TextField,
		Dropdown,
		DataTable,
		IconButton
	},
	props: {
		details: {
			type: Object,
			default: null
		}
	},
	page: {
		title: 'Configure Cookie Policy Table',
		meta: [
			{
				name: 'Configure Cookie Policy Table',
				content: 'Configure a Cookie Policy Table.'
			}
		]
	},
	setup () {
		return {
			showSnackbar,
			brandOptions,
			clientMultiBranded,
			defaultBrandInSelect,
			singleBrand
		}
	},
	data () {
		return {
			formData: {
				policyTableId: null,
				policyTableName: '',
				brand: defaultBrandInSelect.value,
				createdByName: '',
				createdDate: '',
				modifiedByName: '',
				modifiedDate: '',
				selectedCookieScripts: [],
				policyTableDescription: '',
				scriptDetailsForm: {
					cookieName: '',
					cookieScriptId: Number,
					cookieDescription: '',
					cookieScriptChannelId: Number
				}
			},
			selectedCookieScript: null,
			loading: true,
			saving: false,
			sortBy: 'displayOrder',
			sortDesc: false,
			showScriptDetailsModal: false,
			isValid: false,
			editMode: false,
			maxDisplayTextLength: 100,
			scriptTableHeader: [
				{
					text: 'Cookie Script',
					value: 'cookieName',
					sortable: false
				},
				{
					text: 'Cookie Category',
					value: 'categoryName',
					sortable: false
				},
				{
					text: 'Cookie Script Description',
					value: 'cookieDescription',
					sortable: false
				},
				{
					text: 'Display Order',
					value: 'displayOrder',
					sortable: false
				},
				{
					text: 'Action',
					value: 'action',
					sortable: false
				}
			],
			UNIVERSAL_BRAND
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		...mapGetters('cookiePolicyTables', ['cookiePolicyTables', 'currentPolicyTableId']),
		...mapGetters('cookieCategories', ['cookieCategories']),
		...mapGetters('cookieScripts', ['cookieScripts']),
		isEditMode () {
			return this.currentPolicyTableId > 0 && this.currentPolicyTableId !== undefined
		},
		scriptHeadersComputed () {
			// Hide the 'action' column if it would be empty
			const headersComputed = this.scriptTableHeader
			if (
				!this.userCanCreateUpdate &&
				!this.userCanDelete
			) {
				const actionHeader = headersComputed.findIndex(
					header => header.value === 'action'
				)
				headersComputed.splice(actionHeader, 1)
			}
			return headersComputed
		},
		availableCookieScripts () {
			const selectedCookieScriptIds = this.formData.selectedCookieScripts.map(
				x => {
					return x.cookieScriptId
				}
			)
			const availableScripts = this.cookieScripts.filter(
				x => !selectedCookieScriptIds.includes(x.cookieScriptId)
			)

			let filteredAvailableCookieScripts = []
			if (this.formData.brand === UNIVERSAL_BRAND) {
				filteredAvailableCookieScripts = availableScripts.filter(({ brandId }) => brandId === UNIVERSAL_BRAND).map(script => ({
					...script,
					text: script.cookieScriptName,
					value: script.cookieScriptId
				}))
				return filteredAvailableCookieScripts
			} else {
				filteredAvailableCookieScripts = availableScripts.filter(({ brandId }) => brandId === this.formData.brand || brandId === UNIVERSAL_BRAND).map(script => ({
					...script,
					text: script.cookieScriptName,
					value: script.cookieScriptId
				}))
				return filteredAvailableCookieScripts
			}
		},
		cookiesForPreview () {
			const cookies = []

			this.formData.selectedCookieScripts.forEach(script => {
				const cookieDetails = this.cookieScripts.find(
					x => x.cookieScriptId === script.cookieScriptId
				)

				const cookie = {
					cookieScriptChannelId: script.cookieScriptChannelId,
					cookieName: script.cookieName,
					browserCookieNames: cookieDetails.namesOfCookiesInScript,
					cookieDescription: cookieDetails.displayText, // Get the original untrimmed text
					informationUrl: cookieDetails.informationUrl,
					cookieExpiry: cookieDetails.expiry || 0,
					displayOrder: script.displayOrder
				}
				cookies.push(cookie)
			})

			cookies.sort((a, b) => {
				return a.displayOrder - b.displayOrder
			})

			return cookies
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_POLICY_TABLES)
		},
		filteredCategories () {
			let filteredCategories = []
			if (this.formData.brand === UNIVERSAL_BRAND) {
				filteredCategories = this.cookieCategories.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
				return filteredCategories
			} else {
				filteredCategories = this.cookieCategories.filter(({ brandId }) => brandId === this.formData.brand || brandId === UNIVERSAL_BRAND)
				return filteredCategories
			}
		},
		brandLabel () {
			return `Assign to Brand *`
		},
		filteredBrandOptions () {
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)

			if (containsUniversal || !this.isEditMode) {
				return brandOptions.value
			} else {
				return [
					UNIVERSAL_BRAND_ITEM,
					...brandOptions.value
				]
			}
		}
	},
	async mounted () {
		await waitForLoad()
		this.preventScrolling()
		await Promise.all([
			this.getCookieCategories(),
			this.getCookieScripts()
		])
		if (this.isEditMode) {
			await this.getCookiePolicyTable(this.currentPolicyTableId).then(
				response => {
					this.initializeForm(response)
				}
			)
			this.$emit('edit-mode', true)
		}
		this.loading = false
		this.allowScrolling()
	},
	methods: {
		...mapActions('cookieCategories', ['getCookieCategories']),
		...mapActions('cookieScripts', [
			'getCookieScripts',
			'getCookieScript',
			'updateCookieScript'
		]),
		...mapActions('cookiePolicyTables', [
			'createCookiePolicyTable',
			'updateCookiePolicyTable',
			'updateCookiePolicyTableScripts',
			'publishCookiePolicyTable',
			'getCookiePolicyTable'
		]),
		preventScrolling () {
			document.documentElement.classList.add('prevent-scroll')
		},
		allowScrolling () {
			document.documentElement.classList.remove('prevent-scroll')
		},
		cancelDetails () {
			this.$router.push({ name: 'policy-tables' })
		},
		initializeForm (cookiePolicyTableScripts) {
			const currentPolicyTable = this.cookiePolicyTables.find(
				x => x.policyTableId === this.currentPolicyTableId
			)
			this.formData.policyTableId = currentPolicyTable.policyTableId
			this.formData.policyTableName = currentPolicyTable.policyTableName
			this.formData.policyTableDescription =
				currentPolicyTable.policyTableDescription
			this.formData.brand = currentPolicyTable.brandId
			this.formData.createdByName = currentPolicyTable.createdByName
			this.formData.modifiedByName = currentPolicyTable.modifiedByName
			this.reformatDate(
				currentPolicyTable.createdDate,
				currentPolicyTable.modifiedDate
			)
			if (cookiePolicyTableScripts.length > 0) {
				this.formData.selectedCookieScripts = Array.from(
					cookiePolicyTableScripts
				)

				this.formData.selectedCookieScripts.forEach(script => {
					// Add ChannelId to each selected cookie script. Required for validation in backend
					const channelId = this.cookieScripts.find(
						x => x.cookieScriptId === script.cookieScriptId
					).cookieScriptChannelId
					script.cookieScriptChannelId = channelId
				})
			}
		},
		reformatDate (createdDate, modifiedDate) {
			// created date
			let formattedCreatedDate = new Date(createdDate)
			// Requested by JK to make dates UK time, currently the database only returns Oregon time
			formattedCreatedDate.setHours(formattedCreatedDate.getHours() + 8)
			formattedCreatedDate = formattedCreatedDate.toLocaleTimeString([], {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			})
			this.formData.createdDate = formattedCreatedDate
			// edited date
			let formattedLastEditDate = new Date(modifiedDate)
			// Requested by JK to make dates UK time, currently the database only returns Oregon time
			formattedLastEditDate.setHours(formattedLastEditDate.getHours() + 8)
			formattedLastEditDate = formattedLastEditDate.toLocaleTimeString([], {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			})
			this.formData.modifiedDate = formattedLastEditDate
		},
		trimText (textToTrim, maxTextLength) {
			return textToTrim.length > maxTextLength
				? textToTrim.substring(0, maxTextLength - 1) + '…'
				: textToTrim
		},
		generateSelectedScriptsObject () {
			const selectedCookieScripts = this.formData.selectedCookieScripts
			const selectedScriptsObject = {
				cookiePolicyTableId: this.currentPolicyTableId,
				scripts: []
			}
			selectedCookieScripts.forEach(script => {
				const scriptDetails = {
					cookieScriptId: script.cookieScriptId,
					displayOrderId: script.displayOrder,
					// ChannelId is required for backend validation method
					cookieScriptChannelId: script.cookieScriptChannelId
				}
				selectedScriptsObject.scripts.push(scriptDetails)
			})
			return selectedScriptsObject
		},
		async proceedFromDetails () {
			this.saving = true
			if (this.isEditMode) {
				const updatedPolicyTable = {
					cookiePolicyTableId: this.currentPolicyTableId,
					cookiePolicyTableName: this.formData.policyTableName,
					cookiePolicyTableDescription: this.formData.policyTableDescription,
					brandId: this.formData.brand,
					isArchived: false
				}
				const updatedScriptsObject = this.generateSelectedScriptsObject()
				await this.updateCookiePolicyTable(updatedPolicyTable)
				await this.updateCookiePolicyTableScripts(updatedScriptsObject)
					.then(() => {
						this.showSnackbar(`Cookie Policy Table ${updatedPolicyTable.cookiePolicyTableId} Updated`)
					})
					.catch(() => {
						this.showSnackbar({ text: 'Error updating Cookie Policy Table', color: 'red' })
					})
			} else {
				const policyTable = {
					policyTableName: this.formData.policyTableName,
					policyTableDescription: this.formData.policyTableDescription,
					brandId: this.formData.brand
				}
				await this.createCookiePolicyTable(policyTable).then(result => {
					this.formData.policyTableId = result.policyTableId
					this.showSnackbar(`Cookie Policy Table ${result.policyTableId} Created`)
				}).catch(() => {
					this.showSnackbar('Error creating Cookie Policy Table')
				})
				const selectedScriptsObject = this.generateSelectedScriptsObject()
				await this.updateCookiePolicyTableScripts(selectedScriptsObject)
					.then(() => {
						this.saving = false
					})
					.catch(() => {
						this.showSnackbar({ text: 'Error creating Cookie Policy Table Script Configuration', color: 'red' })
					})
			}
			await this.publishCookiePolicyTable(this.formData.policyTableId)
				.catch(() => {
					this.showSnackbar({ text: 'Error publishing Cookie Policy Table', color: 'red' })
				})
				.finally(() => {
					this.$emit('next')
				})
			this.saving = false
		},
		async updateDetails () {
			const updatedPolicyTable = {
				cookiePolicyTableId: this.currentPolicyTableId,
				cookiePolicyTableName: this.formData.policyTableName,
				cookiePolicyTableDescription: this.formData.policyTableDescription,
				brandId: this.formData.brand,
				isArchived: false
			}
			const updatedScriptsObject = this.generateSelectedScriptsObject()

			this.saving = true
			if (this.userCanCreateUpdate) {
				await this.updateCookiePolicyTable(updatedPolicyTable)
				await this.updateCookiePolicyTableScripts(updatedScriptsObject)
					.then(() => {
						this.showSnackbar(`Cookie Policy Table ${this.currentPolicyTableId} Updated`)
					})
					.catch(() => {
						this.showSnackbar({ text: 'Error updating Cookie Policy Table', color: 'red' })
					})

				await this.publishCookiePolicyTable(this.formData.policyTableId).catch(
					() => {
						this.showSnackbar({ text: 'Error publishing Cookie Policy Table', color: 'red' })
					}
				)
			} else {
				this.showSnackbar({ text: 'You do not have permission to edit this Policy Table', color: 'red' })
			}
			this.saving = false
		},
		addScriptToTable (cookieScriptId) {
			// Ensure user doesn't somehow add the same script more than once
			if (
				this.formData.selectedCookieScripts.findIndex(
					script => script.cookieScriptId === cookieScriptId
				) !== -1
			) {
				this.formData.selectedScript = null
				this.showSnackbar({ text: 'This Cookie Script has already been added', color: 'red' })
				return
			}

			const selectedCookieScript = this.cookieScripts.find(
				script => script.cookieScriptId === cookieScriptId
			)

			const cookieScriptTableObject = {
				cookieScriptId: selectedCookieScript.cookieScriptId,
				cookieName: selectedCookieScript.cookieScriptName,
				categoryName: selectedCookieScript.cookieCategoryName,
				cookieDescription: selectedCookieScript.displayText,
				displayOrder: this.formData.selectedCookieScripts.length + 1,
				// ChannelId is required for backend validation method
				cookieScriptChannelId: selectedCookieScript.cookieScriptChannelId
			}

			this.formData.selectedCookieScripts.push(cookieScriptTableObject)
			this.formData.selectedCategory = null
			this.formData.selectedScript = null
		},
		removeScriptFromTable (cookieScript) {
			const index = this.formData.selectedCookieScripts.findIndex(
				script => script.cookieScriptId === cookieScript.cookieScriptId
			)
			// Update display order of remaining selected scripts
			this.formData.selectedCookieScripts.forEach(script => {
				if (script.displayOrder > cookieScript.displayOrder) {
					script.displayOrder -= 1
				}
			})

			this.formData.selectedCookieScripts.splice(index, 1)
		},
		moveScript (script, direction) {
			if (direction === 'moveUp') {
				const previousScript = this.formData.selectedCookieScripts.find(
					previousScript => {
						return previousScript.displayOrder === script.displayOrder - 1
					}
				)
				script.displayOrder -= 1
				previousScript.displayOrder += 1
			} else if (direction === 'moveDown') {
				const nextScript = this.formData.selectedCookieScripts.find(
					nextScript => {
						return nextScript.displayOrder === script.displayOrder + 1
					}
				)
				script.displayOrder += 1
				nextScript.displayOrder -= 1
			}
		},
		openEditScriptDetailsModal (cookieScript) {
			const untrimmedDisplayText = this.cookieScripts.find(
				script => script.cookieScriptId === cookieScript.cookieScriptId
			).displayText

			this.formData.scriptDetailsForm.cookieName = cookieScript.cookieName
			this.formData.scriptDetailsForm.cookieScriptId =
				cookieScript.cookieScriptId
			this.formData.scriptDetailsForm.cookieDescription = untrimmedDisplayText
			this.formData.scriptDetailsForm.cookieScriptChannelId =
				cookieScript.cookieScriptChannelId

			this.preventScrolling()
			this.showScriptDetailsModal = true
		},
		closeEditScriptDetailsModal () {
			this.showScriptDetailsModal = false
			this.allowScrolling()
			this.clearScriptDetailsForm()
		},
		clearScriptDetailsForm () {
			this.formData.scriptDetailsForm.cookieScriptId = null
			this.formData.scriptDetailsForm.cookieDescription = null
			this.formData.scriptDetailsForm.cookieScriptChannelId = null
		},
		async updateScriptDescription (scriptDetailsForm) {
			this.selectedCookieScript = null

			await this.getCookieScript(scriptDetailsForm.cookieScriptChannelId)
				.then(response => {
					this.selectedCookieScript = response
				})
				.catch(error => {
					console.error(error)
				})

			const parentCategory = this.cookieCategories.find(category => {
				return (
					category.categoryId === this.selectedCookieScript.cookieCategoryId
				)
			})
			const updateScriptObject = {
				cookieScriptChannelId: scriptDetailsForm.cookieScriptChannelId,
				scriptName: scriptDetailsForm.cookieName,
				scriptDescription: scriptDetailsForm.cookieDescription,
				brandId: this.selectedCookieScript.brandId,
				isStrictlyNecessary: parentCategory.isStrictlyCategory,
				displayText: scriptDetailsForm.cookieDescription,
				parentCookieCategoryId: this.selectedCookieScript.cookieCategoryId,
				cookieInfoUrl: this.selectedCookieScript.informationUrl,
				optInScript: this.selectedCookieScript.headOptInScript,
				optInScriptBody: this.selectedCookieScript.bodyOptInScript,
				optInDivBody: this.selectedCookieScript.BodyOptInScriptDivID,
				scriptExpiryInDays: this.selectedCookieScript.scriptExpiryInDays,
				consentExpiryInDays: this.selectedCookieScript.consentExpiryInDays,
				browserCookieNames: this.selectedCookieScript.namesOfCookiesInScript,
				isRunFirst: this.selectedCookieScript.isRunFirst,
				gpcEnabled: this.selectedCookieScript.gpcEnabled,
				optOutScript: this.selectedCookieScript.headOptOutScript,
				optOutScriptBody: this.selectedCookieScript.bodyOptOutScript,
				optOutDivBody: this.selectedCookieScript.BodyOptOutScriptDivID,
				isLegitimateInterest:
					parentCategory.scriptType === 'Legitimate Interest',
				scriptPlacementTypeId: this.selectedCookieScript.scriptPlacementTypeId
			}
			await this.updateCookieScript(updateScriptObject)
				.then(() => {
					this.showSnackbar('Cookie Script Description updated successfully!')
				})
				.catch(error => {
					this.showSnackbar({ text: 'Error updating Cookie Policy Table', color: 'red' })
					console.error(error)
				})
			// Update the description in the display table with a trimmed version
			this.formData.selectedCookieScripts.find(
				x =>
					x.cookieScriptChannelId === scriptDetailsForm.cookieScriptChannelId
			).cookieDescription = scriptDetailsForm.cookieDescription
			this.selectedCookieScript = null
			this.showScriptDetailsModal = false
			this.allowScrolling()
		}
	}
}
</script>

<style lang="scss" scoped>
.cassie-html-field-container > div {
	padding-top: 16px !important;
}

.cassie-html-error-container {
	color: white;
}

.cassie-disabled-textarea {
	padding: 10px !important;
	color: var(--global-cassie-disabled-textarea-color);
	border: var(--global-cassie-disabled-textarea-border);
}
</style>
