<template>
	<div>
		<v-row class="px-2 mx-1">
			<v-col cols="12">
				<table class="cookie-policy-table">
					<caption style="display: none;">
						Cookie Policy Table
					</caption>
					<thead>
						<tr>
							<th scope="col">
								Cookie Script
							</th>
							<th scope="col">
								Cookie Name(s)
							</th>
							<th scope="col">
								Cookie Script Description
							</th>
							<th scope="col">
								More Information
							</th>
							<th scope="col">
								Cookie Expiry
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="item in cookies"
							:key="item.cookieScriptChannelId"
						>
							<td>
								{{ item.cookieName }}
							</td>
							<td>
								{{ item.browserCookieNames }}
							</td>
							<td v-dompurify-html="item.cookieDescription" />
							<td>
								{{ item.informationUrl }}
							</td>
							<td>
								{{ item.cookieExpiry }}
							</td>
						</tr>
					</tbody>
				</table>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: 'cookie-policy-preview',
	props: {
		cookies: {
			type: Array
		}
	},
	data () {
		return {}
	},
	computed: {},
	created () {},
	methods: {
		generatePreview () {
		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep .cookie-policy-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
	border-radius: 10px 10px 0 0;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(167, 205, 224, 1);
  -moz-box-shadow: 0px 0px 40px 0px rgba(167, 205, 224, 1);
  box-shadow: 0px 0px 40px 0px rgba(167, 205, 224, 1);
  margin-bottom: 15px;
}

::v-deep .cookie-policy-table > thead {
  background: none;
  color: white;
  font-weight: bold;
  text-align: left;
}

::v-deep .cookie-policy-table > thead > tr > th:nth-child(2) {
    width: 40%;
}

::v-deep .cookie-policy-table > tbody > tr > td:nth-child(2) {
    width: 40%;
	word-break: break-word;
}

::v-deep .cookie-policy-table > thead > tr > th {
  background: #0678aa;
  width: 25%;
	padding: 8px 15px;
}

::v-deep .cookie-policy-table > thead > tr > th:first-child {
  border-radius: 10px 0 0 0;
}

::v-deep .cookie-policy-table > thead > tr > th:last-child {
  border-radius: 0 10px 0 0;
}

::v-deep .cookie-policy-table > tbody > tr > td {
  padding: 8px 15px;
}

::v-deep .cookie-policy-table > tbody > tr:nth-child(even) {
  background: #e8f4f7;
}

::v-deep .cookie-policy-table > thead > tr > th.group-header {
  border-radius: 10px 10px 0 0;
  background-color: #666;
}

::v-deep .cookie-policy-table > thead > tr.no-radius > th {
  border-radius: 0 0 0 0;
}
</style>
