<template>
	<!-- eslint-disable max-lines-->
	<div>
		<div class="cassie-vertical-md">
			<div
				v-if="formData.createdDate"
				class="text-caption"
			>
				Created By: {{ formData.createdByName }}
				{{ formData.createdDate }}
				<span
					v-if=" isEditMode && formData.modifiedByName !== ' ' && formData.modifiedDate !== ' '"
				>
					| Last Edited By: {{ formData.modifiedByName }} {{ formData.modifiedDate }}
				</span>
			</div>
			<SectionCard>
				<template #title>
					Details
				</template>
				<template #subtitle>
					Here you can create the code to copy into your webpage to
					display your cookie policy table. You can control the table's
					position by inputting the Id of the Div on your page in which
					the policy table should appear, choose the language that it
					should display in and how it is grouped. Once you have made
					your selections, please click "Generate embed code".
				</template>
				<template #body>
					<div class="d-flex flex-row cassie-horizontal-sm">
						<Dropdown
							v-model="formData.selectedLanguageId"
							:disabled="!userCanCreateUpdate"
							label="Language*"
							:items="languageOptions"
							class="cassie-input-width-md"
							item-text="languageName"
							item-value="languageID"
							custom-sort
							rules="required"
							@change="changeSelections"
						/>
						<TextField
							v-model="formData.divId"
							:disabled="!userCanCreateUpdate"
							light
							outlined
							dense
							label="Div Id*"
							class="cassie-input-width-md"
							rules="required"
							@change="changeSelections"
						/>
					</div>
					<div class="d-flex flex-row cassie-horizontal-sm align-center">
						<div class="cassie-input-width-sm">
							<div class="text-subtitle-2">
								Grouping
							</div>
							<v-radio-group
								v-model="formData.groupingId"
								class="mt-0 pt-0"
								row
								@change="changeSelections"
							>
								<v-radio
									:disabled="!userCanCreateUpdate"
									label="None"
									value="0"
								/>
								<v-radio
									:disabled="!userCanCreateUpdate"
									label="By Type"
									value="1"
								/>
							</v-radio-group>
						</div>
						<v-spacer />
						<PrimaryActionButton
							v-if="userCanCreateUpdate"
							class="px-0"
							:disabled="haveSelectionsChanged === false"
							@click="generateEmbedCode"
						>
							Generate Embed Code
						</PrimaryActionButton>
					</div>
				</template>
			</SectionCard>
			<SectionCard v-if="displayEmbedCode">
				<template #title>
					Embed Code {{ formData.PolicyTableName }}
				</template>
				<template #title-action>
					<div>
						<v-progress-circular
							v-if="generatingEmbedCode"
							:size="20"
							:width="3"
							color="blue darken-2"
							indeterminate
						/>
					</div>
				</template>
				<template #subtitle>
					This code will automatically load the table and place it onto your page.
				</template>
				<template #body>
					<div
						v-if="displayEmbedCode"
						style="font-family:'Monaco', monospace"
					>
						<v-card
							width="100%"
							dark
							style="font-family:'Monaco', monospace"
						>
							<div
								v-if="generatingEmbedCode"
							>
								<span
									style="font-family:'Monaco', monospace"
								>Generating Embed Code...</span>
							</div>
							<div
								v-if="!generatingEmbedCode"
								style="font-family:'Monaco', monospace"
							>
								<pre><code
									class="language-javascript"
									v-text="syntaxHighlight(formData.embedCode)"
								/></pre>
							</div>
						</v-card>
					</div>
				</template>
			</SectionCard>
			<SectionCard v-if="displayEmbedCode">
				<template #title>
					Installation Instructions
				</template>
				<template #body>
					<div class="cassie-vertical-sm">
						<div class="cassie-vertical-sm">
							<div class="text-subtitle-2 grey--text text--darken-2">
								How to Install
							</div>
							<div class="body-2 grey--text text--darken-1">
								<p>
									To include the table on the cookie policy page of your website
									follow the installation instructions below:
								</p>
								<p>
									<ol>
										<li>
											Place an empty div on your webpage where you would like the
											table to appear.
										</li>
										<li>
											Give the div the ID you specified in the configuration form
											above.
										</li>
										<li>
											Copy the snippet of JavaScript code above and place it at
											the bottom of your web page.
										</li>
										<li>
											Style the table in any way you want using CSS, using the
											CSS ID Selector "CookiePolicyTable".
										</li>
									</ol>
								</p>
							</div>
						</div>
						<div class="cassie-vertical-sm">
							<div class="text-subtitle-2 grey--text text--darken-2">
								Variables
							</div>
							<div class="body-2 grey--text text--darken-1">
								<p>
									All of these variables have been pre-populated for you from the
									configuration settings you entered at the top of this page.
								</p>
							</div>
						</div>
						<DataTable
							:headers="staticDataHeaders"
							:items="staticDataVariables"
							item-key="id"
							hide-default-footer
						/>
					</div>
				</template>
			</SectionCard>
		</div>
	</div>
</template>

<script>
/* eslint-disable max-lines */
import { mapGetters, mapActions } from 'vuex'
import Prism from 'prismjs'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import { CAN_CREATE_UPDATE_POLICY_TABLES } from '../../../../../../../shared/permissions/admin-portal-permissions.js'
import { isMultiBrand, brandOptions } from '../../../../../../../shared/state/brands.js'
import 'prismjs/components/prism-javascript'

export default {
	components: {
		SectionCard,
		PrimaryActionButton,
		Dropdown,
		TextField,
		DataTable
	},
	props: {
		details: {
			type: Object,
			default: null
		}
	},
	page: {
		title: 'Configure Cookie Policy Table',
		meta: [
			{
				name: 'Configure Cookie Policy Table',
				content: 'Configure a Cookie Policy Table.'
			}
		]
	},
	setup () {
		return {
			showSnackbar,
			isMultiBrand,
			brandOptions
		}
	},
	data () {
		return {
			formData: {
				divId: '',
				policyTableId: null,
				policyTableName: '',
				languageId: 0,
				groupingId: '0',
				brand: 0,
				createdByName: '',
				createdDate: '',
				modifiedByName: '',
				modifiedDate: '',
				selectedCookieScripts: [],
				language: '',
				languages: [],
				selectedLanguageId: null
			},
			haveSelectionsChanged: false,
			displayEmbedCode: false,
			loading: true,
			saving: false,
			isValid: false,
			editMode: false,
			groupBy: 'groupByNone',
			generatingEmbedCode: false,
			forceRegenEmbedCode: false,
			embedCode: null,
			staticDataHeaders: [
				{
					text: 'Variable Name',
					value: 'variableName',
					sortable: false,
					width: '50%'
				},
				{
					text: 'Description',
					value: 'description',
					sortable: false,
					width: '50%'
				}
			],
			staticDataVariables: [
				{
					id: '1',
					variableName: 'ID',
					description: 'The unique identifier of this policy table'
				},
				{
					id: '2',
					variableName: 'AccessKey ',
					description:
            'The Access Key to use when getting the table from our web handler'
				},
				{
					id: '3',
					variableName: 'DivID',
					description: 'This is the ID of the blank div you created'
				},
				{
					id: '4',
					variableName: '(Optional) LanguageCode',
					description:
            'A code to change the language of the table (if a language has been selected above)'
				},
				{
					id: '5',
					variableName: '(Optional) Grouping',
					description:
            'A code to change how cookies are displayed in the table (0 = no grouping, 1 = grouped by parent type)'
				}
			],
			validationRules: {
				divId: [
					divValue => !!divValue || 'Div id is required',
					divValue => divValue.length <= 50 || 'Div id must be less than 50 characters'
				]
			}
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		...mapGetters('languages', ['assignedLanguages']),
		...mapGetters('cookiePolicyTables', [
			'cookiePolicyTables',
			'currentPolicyTableId',
			'cookiePolicyEmbedCode'
		]),
		languageOptions () {
			const assignedLanguages = [...this.assignedLanguages]
			const languageOptions = [
				{
					languageID: 0,
					languageCode: '',
					languageName: 'None',
					languageLookupID: 0
				}
			]

			languageOptions.push(...assignedLanguages.sort((a, b) => a.languageName.localeCompare(b.languageName)))

			if (!this.languages) {
				this.setLanguagesToNone()
			}

			return languageOptions
		},
		isEditMode () {
			return this.currentPolicyTableId > 0
		},
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_POLICY_TABLES)
		}
	},
	async mounted () {
		this.preventScrolling()

		await Promise.all([
			this.getCookieCategories(),
			this.getCookieScripts(),
			this.getAssignedLanguages()
		]).finally(() => {
			if (!isMultiBrand.value) { this.formData.brand = brandOptions.value[0].value }
			this.isEditMode && this.initializeForm()
			this.loading = false
			this.formData.Languages = this.assignedLanguages

			this.allowScrolling()
		})
	},
	methods: {
		...mapActions('cookieCategories', ['getCookieCategories']),
		...mapActions('cookieScripts', ['getCookieScripts']),
		...mapActions('languages', ['getAssignedLanguages']),
		...mapActions('cookiePolicyTables', ['publishCookiePolicyTable', 'getEmbedCode']),

		async publishAndComplete () {
			this.saving = true
			await this.publishCookiePolicyTable(this.formData.policyTableId)
				.then(() => {
					this.saving = false
					showSnackbar(`Cookie Policy Table ${this.formData.policyTableId} Published`)
				})
				.catch(() => {
					this.showSnackbar({ text: 'Error publishing Cookie Policy Table', color: 'red' })
				})
				.finally(() => {
					this.exitToPolicyTables()
				})
		},
		changeSelections () {
			this.haveSelectionsChanged = true
		},
		preventScrolling () {
			document.documentElement.classList.add('prevent-scroll')
		},
		allowScrolling () {
			document.documentElement.classList.remove('prevent-scroll')
		},
		initializeForm () {
			const currentPolicyTable = this.cookiePolicyTables.find(
				x => x.policyTableId === this.currentPolicyTableId
			)
			this.formData.policyTableId = currentPolicyTable.policyTableId
			this.formData.policyTableName = currentPolicyTable.policyTableName
			this.formData.policyTableDescription =
        currentPolicyTable.policyTableDescription
			this.formData.brand = currentPolicyTable.brandId
			this.formData.createdByName = currentPolicyTable.createdByName
			this.formData.modifiedByName = currentPolicyTable.modifiedByName
			this.reformatDate(
				currentPolicyTable.createdDate,
				currentPolicyTable.modifiedDate
			)
		},
		reformatDate (createdDate, modifiedDate) {
			// created date
			let formattedCreatedDate = new Date(createdDate)
			// Requested by JK to make dates UK time, currently the database only returns Oregon time
			formattedCreatedDate.setHours(formattedCreatedDate.getHours() + 8)
			formattedCreatedDate = formattedCreatedDate.toLocaleTimeString([], {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			})
			this.formData.createdDate = formattedCreatedDate

			// edited date
			let formattedLastEditDate = new Date(modifiedDate)
			// Requested by JK to make dates UK time, currently the database only returns Oregon time
			formattedLastEditDate.setHours(formattedLastEditDate.getHours() + 8)
			formattedLastEditDate = formattedLastEditDate.toLocaleTimeString([], {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			})
			this.formData.modifiedDate = formattedLastEditDate
		},
		async generateEmbedCode () {
			this.displayEmbedCode = true
			this.$emit('display-embed-code', true)
			this.forceRegenEmbedCode = false
			this.generatingEmbedCode = true
			this.formData.embedCode = 'Generating Embed Code...'
			const getEmbedCodeObject = {
				policyTableId: this.formData.policyTableId,
				languageId: this.formData.selectedLanguageId,
				divId: this.formData.divId,
				groupingId: this.formData.groupingId
			}
			await this.getEmbedCode(getEmbedCodeObject)
				.then(() => {
					this.formData.embedCode = this.cookiePolicyEmbedCode
				})
				.catch(error => {
					console.error(error)
					this.formData.embedCode = 'Error generating Embed Code'
				})
				.finally(() => {
					this.generatingEmbedCode = false
					this.haveSelectionsChanged = false
				})
		},
		syntaxHighlight (codeBlock) {
			this.$nextTick(function () {
				Prism.highlightAll()
			})
			return codeBlock
		},
		forceUpdateForSyntaxHighlighting () {
			this.$forceUpdate()
		},
		exitToPolicyTables (resetValidation = () => {}) {
			resetValidation()
			this.$router.push({ name: 'policy-tables' })
		},

		setLanguagesToNone () {
			this.formData.selectedLanguageId = 0
		}
	}
}
</script>
